import { Link } from 'react-router-dom';
import hurdle1 from './img/hurdle-1.jpg';

function Solutions() {
    return (
      <div className="bg-white custom-bg pb-4 sm:py-8">
      <div className="mx-auto max-w-8xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          {/* <h2 className="text-base font-semibold leading-7 text-indigo-600">Solution Areas</h2> */}
          {/* <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900 xs:text-2xl">A wide range of expertise</p> */}
          {/* <p className="mt-6 text-lg leading-8 text-gray-600">Quis tellus eget adipiscing convallis sit sit eget aliquet quis. Suspendisse eget egestas a elementum pulvinar et feugiat blandit at. In mi viverra elit nunc.</p> */}
        </div>
        <div className="mx-auto mt-8 max-w-2xl sm:mt-8 lg:my-8 lg:max-w-5xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-8 lg:max-w-none lg:grid-cols-2 lg:gap-y-12 lg:gap-x-12">
          <Link to="/solutions/web">
            <div className="relative pl-20 text-left pt-2 pb-4 custom-box" >
              <dt className="text-lg font-semibold leading-7 text-gray-900">
               
                <div className="absolute left-5 top-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                  <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                  </svg>
                </div>
               
                Web & Mobile Development 
              </dt>
              <dd className="pt-1 text-base leading-7 text-gray-600">Websites & CMS, Mobile Applications, E-Commerce Solutions (Shopify, WIX)
                
              <a href="/solutions/web" className="pl-2 text-sm font-bold leading-6 text-indigo-700">More<span aria-hidden="true">→</span></a>
              </dd>
              
            </div>
            </Link>
            <Link to="/solutions/software">
            <div className="relative pl-20 text-left pt-2 pb-4 custom-box" >
              <dt className="text-lg font-semibold leading-7 text-gray-900">
                  <div className="absolute left-4 top-4  flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                    </svg>
                  </div>

                Software Development & Modernization
              </dt>
              <dd className="pt-1 text-base leading-7 text-gray-600">C# Blazor & APIs, ReactJS UX Development, SQL Development & ETL Workflows 
              <a href="/solutions/software" className="pl-2 text-sm font-bold leading-6 text-indigo-700">More<span aria-hidden="true">→</span></a>
              </dd>
          
            </div>
            </Link>

            <Link to="/solutions/cloud">
            <div className="relative pl-20 text-left pt-2 pb-4 custom-box" >
            <dt className="text-lg font-semibold leading-7 text-gray-900">
                
                  <div className="absolute left-4 top-4 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                  </div>
             
                Azure Cloud Infrastructure
              </dt>
              <dd className="pt-1 text-base leading-7 text-gray-600">Landing Zones, IAAS/PAAS, Kubernetes, Docker, HADR Solutions, Vulnerability Management 
                {/* Microsoft Azure solutions for cloud-ready businesss. Tenants & Directories, IAAS, PAAS, Multi-region HADR, Compliance, etc.  */}
                
              <a href="/solutions/cloud" className="pl-2 text-sm font-bold leading-6 text-indigo-700">More<span aria-hidden="true">→</span></a>
              </dd>
            </div>
            </Link>

            <Link to="/solutions/security">
            <div className="relative pl-20 text-left pt-2 pb-4 custom-box" >
            <dt className="text-lg font-semibold leading-7 text-gray-900">
  
                  <div className="absolute left-4 top-4 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />
                    </svg>
                  </div>
            
                Compliance & Data Security
              </dt>
              <dd className="pt-1 text-base leading-7 text-gray-600">
                SOC2 & NIST Framework Assessments & Control Development, Data Obfuscation & PQC
                <a href="/solutions/security" className="pl-2 text-sm font-bold leading-6 text-indigo-700">More<span aria-hidden="true">→</span></a>
              </dd>
            </div>
            </Link>
          </dl>
        </div>
      </div>
    </div>
    );
}

export default Solutions;
