import {useEffect, useRef} from 'react'
import Nav from '../headers/nav';
import { HashLink as Link } from 'react-router-hash-link';
import Pricing from '../home/pricing';
import imgBoth from '../img/web-and-mobile.png'
import cSharp from '../img/coding-bundle.jpg'
import nodeJs from '../img/node-js.svg'
import reactJs from '../img/reactjs-logo.jpeg'

function Web() {

  var webEssentialsPoints = ["Basic Website (Up to 5 pages)", "Annual Hosting", "Content & Contact Form", "Technical Support"]

  var customSites = ["20% at signing to start design phase with iterations over 3 weeks.", "40% upon design approval. Implementation begins.", "Final stakeholder review and approval.", "Remaining 40% due on delivery."]

  var seo = ["Site Content Analysis & Initial Baseline Report", "Click target configuration and conversion tracking.", "Monthly Analysis & Reporting"]

  var enterprise = ["Web & Mobile Platform Development Services", "Dedicated Account Rep. & Support", "Custom Development Hours", "SEO & Hosting Included"]

  
  const scollToRef = useRef(null);

  useEffect(() => {

    const scrollToHashElement = () => {
      const { hash } = window.location;
      const elementToScroll = document.getElementById(hash?.replace("#", ""));

      if (!elementToScroll) return;

      window.scrollTo({
        top: elementToScroll.offsetTop - 0,
        behavior: "smooth"
      });
    };


    // if (!trigger) return;

    scrollToHashElement();
    window.addEventListener("hashchange", scrollToHashElement);
    return window.removeEventListener("hashchange", scrollToHashElement);
    
  }, []);
  
  function sc(){
    
    setTimeout(function () {
      var el: HTMLHeadingElement = (scollToRef.current!); //document.getElementById('content');
      el.scrollIntoView({ behavior: 'smooth'});
 }, 100);



  }

  return (
    <div>
      <div className="relative inset-x-0 top-0 z-50 bg-gray-900">
        <Nav />
      </div>


      <div className="isolate bg-white px-6 lg:px-8">
        <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]" aria-hidden="true">
          <div className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
            style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)' }}></div>
        </div>
        <div className="mx-auto max-w-2xl text-center">
          <h1 className="text-3xl font-bold tracking-tight text-gray-700 sm:text-4xl mt-8">Websites & Mobile Apps</h1>
          {/* <p className="mt-4 md:text-xl sm:text-lg leading-6 text-gray-600">Modern trends to build the best experience</p> */}
          {/* <p className="mt-2 text-lg leading-8 text-gray-600">Please use the form below. We respond quickly! </p> */}
        </div>

      </div>

      <div className="overflow-hidden bg-white py-12">
        <div className="mx-auto max-w-5xl px-6 lg:flex lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
            <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
              <h2 className="text-2xl font-bold tracking-tight text-indigo-600 sm:text-3xl">Your Digital Storefront</h2>
              <p className="mt-2 leading-8 text-gray-600">
              <b>We use modern trends to build best-in-class experiences</b>
                {/* <ul className="list-disc pt-4 pl-8 text-sm"> */}
                <ul role="list" className="mt-6 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 sm:grid-cols-2">

                  <li className="flex gap-x-3">
                    <svg className="h-7 w-5 flex-none" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd"></path>
                    </svg>
                    Digital Branding & Assets
                  </li>

                  <li className="flex gap-x-3">
                    <svg className="h-7 w-5 flex-none" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                    </svg>
                    Designing for Mobile
                  </li>

                  <li className="flex gap-x-3">
                    <svg className="h-7 w-5 flex-none" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                    </svg>
                    Content Strategy & Writing
                  </li>

                  <li className="flex gap-x-3">
                    <svg className="h-7 w-5 flex-none" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                    </svg>
                    Search Engine Optimization
                  </li>
                </ul>

              </p>
              <p className="mt-8 md:text-lg sm:text-lg  leading-8 text-gray-600">
                Our team works hard to ensure all boxes are checked! 
                </p>
              {/* <div className="mt-10 flex justify-center">
                <a href="#" onClick={sc} className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  View Web Packages&nbsp;<span aria-hidden="true">&darr;</span></a>
              </div> */}
            </div>
            <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
              <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
                <img src={imgBoth}  alt="" className="aspect-[7/5] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover" />
                {/* "https://images.unsplash.com/photo-1670272502246-768d249768ca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1152&q=80"  */}
              </div>
              {/* <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
                <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                  <img src="https://images.unsplash.com/photo-1605656816944-971cd5c1407f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=768&h=604&q=80" alt="" className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover" />
                </div>
                <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                  <img src="https://images.unsplash.com/photo-1568992687947-868a62a9f521?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1152&h=842&q=80" alt="" className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover" />
                </div>
                <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                  <img src="https://images.unsplash.com/photo-1612872087720-bb876e2e67d1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=768&h=604&q=80" alt="" className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>



      <div className="bg-gray-900 text-gray-100">
        <div className="mx-auto max-w-7xl px-5 lg:px-8">



          {/* <div className="isolate bg-white px-6 py-4 sm:py-8 lg:px-8">
                <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]" aria-hidden="true">
                    <div className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
                        style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)' }}></div>
                </div>
                <div className="mx-auto max-w-2xl text-center">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Web Development</h1>
                    <p className="mt-6 text-lg leading-8 text-gray-600"><i>Maximize your digital storefront potential</i></p>

                </div>
            </div> */}

          {/* <p className="mt-2 text-lg leading-8 text-gray-600">Please use the form below. We respond quickly! </p> */}


          <div className="pb-1 pt-10">
            <div className="mx-auto max-w-7xl ">
              <div className="mx-auto pb-2 max-w-2xl sm:text-center px-6 lg:px-4">
                <h2 ref={scollToRef} className="text-5xl font-bold tracking-tight">Development Packages</h2>
                {/* <p className="text-xl mt-6 leading-8 ">From startup to enterprise </p> */}
                {/* <p className="mt-2 text-sm leading-8 text-gray-600">For questions, <a href="/contact" ><b>Contact Us</b></a> </p> */}
              </div>


              {/* Web Essentials */}
              <Pricing title='Web Essentials'
                dealId={1}
                description="This package gets you internet-ready quickly to kickstart SEO and lead generation."
                pointsTitle="What's Included"
                points={webEssentialsPoints}
                priceTitle="Monthly/Annual"
                price=" $55/$579"
                footer="Save money with an Annual committment."

              />
<br />
{/* <Link
  to="/solutions/web#custom-site-redesign"
  scroll={el => el.scrollIntoView({ block: 'end' })}
  // etc...
>Link to Hash Fragment */}
<div id="custom-site-redesign"></div>
              <Pricing title='Custom Sites & Redesigns'
                dealId={2}
                description="Pricing starts at $3,500 and increases based on page count and overall complexity. All projects are properly scoped so costs can be accurately reflected in the final quote."
                pointsTitle="What's Included"
                points={customSites}
                priceTitle="Get started today"
                price="$500"
                footer="Please use the Contact Form for questions."

              />
         

<br />
              <Pricing title='SEO & Ad Management'
                dealId={3}
                description="Pricing starts at $250 per month and may increase based on count, complexity, or frequency. This does not include the buget for keywords or ads, that amount is additional and billed as a separate line item."
                pointsTitle="What's Included"
                points={seo}
                priceTitle="Get started today"
                price="$250"
                footer="Please use the Contact Form for questions."

              />
<br />
              <Pricing title='Enterprise Services'
                dealId={4}
                description="This package is for companies that need dedicated content support or building a custom platform. Pricing starts at $500 per month and increases based on requirements."
                pointsTitle="What's Included"
                points={enterprise}
                priceTitle="Get started today"
                price="$500"
                footer="Please use the Contact Form for questions."

              />
<br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Web;