import hurdle from '../img/hurdle.png';
import hurdle1 from '../img/heros/hurdle-now-ai.png';
import hurdle0 from '../img/heros/hurdle-now-web-mobiler.png';
import hurdle2 from '../img/heros/hurdle-now-cloud.png';
import hurdle3 from '../img/heros/hurdle-now-data.png';
import hurdle4 from '../img/heros/hurdle-now-infra.png';

import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from 'react';

function Hero() {

  const [hurdle0Visible, setHurdle0Visible] = useState<boolean>(true);
  const [hurdle1Visible, setHurdle1Visible] = useState<boolean>(false);
  const [hurdle2Visible, setHurdle2Visible] = useState<boolean>(false);
  const [hurdle3Visible, setHurdle3Visible] = useState<boolean>(false);
  const [hurdle4Visible, setHurdle4Visible] = useState<boolean>(false);

  const [hurdleIndex, setHurdleIndex] = useState<number>(1);



  useEffect(() => {

    const intervalId = setInterval(function () {

      if (hurdleIndex == 4) {
        setHurdleIndex(1);
      }
      else {
        setHurdleIndex(prevCount => prevCount + 1);
      }


      console.log(hurdleIndex)

      setHurdle2Visible(false)
      setHurdle3Visible(false)
      setHurdle4Visible(false)
      setHurdle1Visible(false)
      setHurdle0Visible(false)

      setTimeout(() => {

        switch (hurdleIndex) {
          case 1:
            setHurdle1Visible(true)
            setHurdle2Visible(false)
            setHurdle3Visible(false)
            setHurdle4Visible(false)
            setHurdle0Visible(false)

            break;
          case 2:
            setHurdle2Visible(true)
            setHurdle1Visible(false)
            setHurdle3Visible(false)
            setHurdle4Visible(false)
            setHurdle0Visible(false)
            break;
          case 3:
            setHurdle3Visible(true)
            setHurdle1Visible(false)
            setHurdle2Visible(false)
            setHurdle4Visible(false)
            setHurdle0Visible(false)
            break;
          case 4:
            setHurdle4Visible(true)
            setHurdle1Visible(false)
            setHurdle2Visible(false)
            setHurdle3Visible(false)
            setHurdle0Visible(false)
            break;
        }


      }, 1000);



    }, 3000);

    return () => clearInterval(intervalId);

  }, [hurdleIndex]);

  return (

    <div className="relative isolate overflow-hidden bg-gray-800 pt-10">

      <svg className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
        <defs>
          <pattern id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y="-1" className="overflow-visible fill-gray-800/20">
          <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" stroke-width="0" />
        </svg>
        <rect width="100%" height="100%" stroke-width="0" fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
      </svg>
      
      <div className="pt-10 pb-0 mx-auto grid max-w-4xl grid-cols-1 gap-x-12 lg:gap-y-8">

        <div className="pt-6 lg:col-end-1 lg:w-full lg:pb-8">
        <i className="mt-6 md:mt-4 text-lg leading-8 text-gray-300">Solving IT hurdles with modern technology solutions.</i>

          <h1 className="pt-2 text-4xl font-bold tracking-tight text-white sm:text-3xl">Software | Cloud | Data | Security</h1>
          
        </div>
        <div className="flex flex-wrap gap-6 sm:gap-8 pl-24">

          <div className='pt-4 h-22 pb-4 hidden md:block'> 
          {/* <AnimatePresence>

            {hurdle0Visible && (
              <motion.div
                key="h0"
                animate={{ y: 20 }}
                exit={{ x: 100, opacity: 0, speed: 1 }}
                className="hurdle"
              >
                <img src={hurdle0} className='w-[12rem]' alt="App screenshot" />
              </motion.div>
            )}

            {hurdle1Visible && (
              <motion.div
                key="h1"
                animate={{ y: 20 }}
                exit={{ x: 100, opacity: 0, speed: 1 }}
                className="hurdle"
              >
                <img src={hurdle2} className='w-[12rem]' alt="App screenshot" />

              </motion.div>
            )}

            {hurdle2Visible && (
              <motion.div
                key="h2"

                animate={{ y: 20 }}
                exit={{ x: 100, opacity: 0, speed: 1 }}
                className="hurdle"
              >
                <img src={hurdle3} className='w-[12rem]' alt="App screenshot" />
              </motion.div>
            )}

            {hurdle3Visible && (
              <motion.div
                key="h3"

                animate={{ y: 20 }}
                exit={{ x: 100, opacity: 0, speed: 1 }}
                className="hurdle"
              >
                <img src={hurdle1} className='w-[12rem]' alt="App screenshot" />
              </motion.div>
            )}

            {hurdle4Visible && (
              <motion.div
                key="h4"

                animate={{ y: 20 }}
                exit={{ x: 100, opacity: 0, speed: 1 }}
                className="hurdle"
              >
                <img src={hurdle4} className='w-[12rem]' alt="App screenshot" />
              </motion.div>
            )}

          </AnimatePresence> */}
           <img src={hurdle} className='w-[6rem]' alt="App screenshot" />
          </div>
      </div>
      </div>
    </div>
  );
}

export default Hero;

{/* <span className="rounded-full bg-indigo-500/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-400 ring-1 ring-inset ring-indigo-500/20">Software</span>
          <span className="rounded-full bg-indigo-500/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-400 ring-1 ring-inset ring-indigo-500/20">Cloud</span>
          <span className="rounded-full bg-indigo-500/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-400 ring-1 ring-inset ring-indigo-500/20">Data</span>
          <span className="rounded-full bg-indigo-500/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-400 ring-1 ring-inset ring-indigo-500/20">Security</span> */}
{/* <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
            <span>Just shipped v1.0</span>
            <svg className="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
            </svg>
          </span> */}

{/* <div className="pointer-events-none relative inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 mt-4">
              <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-gray-100 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
                <p className="text-sm leading-6">
                  <a href="#">
                    <strong className="font-semibold">NEW Small Business Web Essentials Package</strong>&nbsp;
                    <br />
                    The first three new customers get $100 off!  See below&nbsp;<span aria-hidden="true">&rarr;
                    </span>
                  </a>
                </p>
                <button type="button" className="-m-1.5 flex-none p-1.5">
                  <span className="sr-only">Dismiss</span>
                  <svg className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                  </svg>
                </button>
              </div>
            </div>
          </div> */}
