import React, { useState } from 'react';
import logo from './logo.svg';
import grid from './grid.svg';

import Nav from '../headers/nav';
import Hero from '../home/hero';
import Solutions from '../home/solutions';
import Modernization from '../home/modernization';
import Cloud from '../home/cloud';
// import Pricing from '../home/pricing';
// import NewIdea from '../home/newIdea';
//import { BrowserRouter, Route} from 'react-router-dom';
import { createClient } from "@sanity/client"

export const client = createClient({
  projectId: "y12zu0ze", 
  dataset: "production", 
  apiVersion: "2024-03-11",
  // Set to `true` for production environments
  useCdn: false, 
})


function Home() {

  return (
    <div className="App">
       <header className="absolute inset-x-0 top-0 z-50">
      <Nav />      
      </header>
      <Hero />
      <Solutions />
      {/* <Cloud /> */}
      {/* <Modernization /> */}
    
<br />

  {/* Custom Data Connectors (Monday to Database)

  Cloud Experts
 */}

{/* 
<div className="bg-gray-900 py-16 sm:py-24 lg:py-32">
  <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
    <div className="max-w-xl text-3xl font-bold tracking-tight text-white sm:text-4xl lg:col-span-7">
      <h2 className="inline sm:block lg:inline xl:block">Want product news and updates?</h2>
      <p className="inline sm:block lg:inline xl:block">Sign up for our newsletter.</p>
    </div>
    <form className="w-full max-w-md lg:col-span-5 lg:pt-2">
      <div className="flex gap-x-4">
        <label htmlFor="email-address" className="sr-only">Email address</label>
        <input id="email-address" name="email" type="email" autoComplete="email" required className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" placeholder="Enter your email" />
        <button type="submit" className="flex-none rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Subscribe</button>
      </div>
      <p className="mt-4 text-sm leading-6 text-gray-300">We care about your data. Read our <a href="#" className="font-semibold text-white">privacy&nbsp;policy</a>.</p>
    </form>
  </div>
</div> */}



            
{/* <NewIdea /> */}

{/* 
      <div className="bg-indigo-800 sm:py-8">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="relative isolate overflow-hidden px-6 py-14 sm:px-24 xl:py-2">
            <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">Have an idea for a new product?</h2>
            <p className="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-300">We'll get you to market, <b><u>quickly</u></b></p>
          <br />
          <div className="relative isolate overflow-hidden px-6 text-center">
            <a href="contact" >
              <button type="submit" className="flex-none rounded-md bg-white px-3.5 py-2.5 text-lg font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
                Let's Get Started!</button>
            </a>
          </div>
          </div>
          
        </div>
      </div> */}
{/*     
      <div className="bg-white py-4 sm:py-8">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-2xl sm:text-center">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">The Essentials</h2>
      <p className="mt-2 text-lg leading-8 text-gray-600">Our essentials package is designed to get you up and running quickly. </p>
      <p className="mt-2 text-sm leading-8 text-gray-600">For questions, <a href="/contact" ><b>Contact Us</b></a> </p>
    </div>
    </div>
    </div> */}
    {/* <div className="bg-white py-4 sm:py-8">
      <Pricing />
      </div> */}
      
      


    </div>
  );
}

export default Home;